import React, { useState, useEffect, useRef } from 'react';
import FetchData from '../../hooks/fetchData';
import { BASE_URL } from '../../config';
import { FaEye, FaSearch, FaTimes } from 'react-icons/fa';

const OrderTable = () => {
  const [visibleDetails, setVisibleDetails] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null); // New state for modal
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(''); // Search state
  const { data: orders, error } = FetchData(`${BASE_URL}/orders`);
  const inputRef = useRef(null);

  // Set data to orders
  useEffect(() => {
    if (orders) {
      setData(orders);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [orders]);

  if (error) {
    return <p>Error loading data</p>;
  }

  if (!data.length) {
    return <p>Loading...</p>;
  }

  // Toggle details
  const toggleDetails = (orderId) => {
    setVisibleDetails(visibleDetails === orderId ? null : orderId);
    const selected = data.find((order) => order._id === orderId);
    setSelectedOrder(selected || null);
  };

  // Close modal
  const closeModal = () => {
    setVisibleDetails(null);
    setSelectedOrder(null);
  };

  // Filter orders based on user name or phone number
  const filteredData = data.filter(
    (orderItem) =>
      orderItem.user.name.toLowerCase().includes(search.toLowerCase()) ||
      String(orderItem.user.phone).toLowerCase().includes(search.toLowerCase())
  );

  const isNotFound = filteredData.length === 0 && search.trim() !== '';

  return (
    <div className='p-4'>
      <div className='rounded-md px-4 bg-white label lg:w-[100%] md:w-[70%] border-none items-center flex justify-between mb-5'>
        <FaSearch />
        <input
          ref={inputRef}
          type='search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search by Product Name or Category'
          className='px-4 bg-transparent focus:outline-none md:w-[100%] lg:w-[100%] h-[45px] items-center'
        />
      </div>
      {isNotFound ? (
        <div className='flex justify-center items-center h-32'>
          <p className='text-red-500 text-lg font-bold'>No orders found</p>
        </div>
      ) : (
        <div className='overflow-x-auto'>
          {/* Table */}
          <table className='min-w-full bg-white border rounded-lg shadow-md'>
            <thead className='bg-gray-200 text-center'>
              <tr>
                <th className='p-4'>Product</th>
                <th className='p-4'>User</th>
                <th className='p-4'>Phone</th>
                <th className='p-4 hidden md:table-cell'>Address</th>
                <th className='p-4 hidden md:table-cell'>Single Price</th>
                <th className='p-4 hidden md:table-cell'>Quantity</th>
                <th className='p-4 hidden md:table-cell'>Total Payment</th>
                <th className='p-4 hidden lg:table-cell'>Status</th>
                <th className='p-4'>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((orderItem) => (
                <React.Fragment key={orderItem._id}>
                  <tr className='border-t text-center hover:bg-gray-100 transition-colors'>
                    <td className='p-4'>
                      <div className='flex justify-center items-center space-x-2'>
                        {orderItem.products.slice(0, 2).map((item) => (
                          <div
                            key={item.product._id}
                            className='flex flex-col items-center w-14 h-14'
                          >
                            <span>{item.product?.name}</span>
                          </div>
                        ))}
                        {orderItem.products.length > 2 && (
                          <div className='text-gray-500 mt-2'>
                            <button
                              onClick={() => toggleDetails(orderItem._id)}
                            >
                              and {orderItem.products.length - 2} more...
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{orderItem.user.name}</td>
                    <td>{orderItem.user.phone}</td>
                    <td className='p-4 hidden md:table-cell'>
                      {orderItem.shippingAddress && (
                        <div>
                          {orderItem.shippingAddress.street},<br />
                          {orderItem.shippingAddress.city},<br />
                          {orderItem.shippingAddress.zipCode},<br />
                          {orderItem.shippingAddress.country}
                        </div>
                      )}
                    </td>
                    <td className='p-4 hidden md:table-cell'>
                      {orderItem.products.slice(0, 2).map((item) => (
                        <div key={item.product._id}>
                          {item.product.price} cfh
                        </div>
                      ))}
                    </td>
                    <td className='p-4 hidden md:table-cell'>
                      {orderItem.products.slice(0, 2).map((item) => (
                        <div key={item.product._id}>{item.quantity}</div>
                      ))}
                    </td>
                    <td className='p-4 hidden md:table-cell'>
                      {orderItem.totalAmount} cfh
                    </td>
                    <td className='p-4 hidden lg:table-cell'>
                      <span
                        className={`px-2 py-1 rounded-md ${
                          orderItem.status === 'Success'
                            ? 'bg-green-100 text-green-700'
                            : orderItem.status === 'Pending'
                            ? 'bg-gray-100 text-gray-700'
                            : 'bg-red-100 text-red-700'
                        }`}
                      >
                        {orderItem.status}
                      </span>
                    </td>
                    <td className='p-4'>
                      <div className='flex justify-center space-x-2'>
                        <button
                          className='text-blue-500 text-xl hover:underline'
                          onClick={() => toggleDetails(orderItem._id)}
                        >
                          <FaEye />
                        </button>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* Modal Popup */}
          {selectedOrder && (
            <div className='fixed inset-0 flex items-center justify-center z-50'>
              <div
                className='absolute inset-0 bg-black opacity-50'
                onClick={closeModal}
              ></div>
              <div className='bg-white p-6 rounded-lg z-10'>
                <div className='flex justify-between'>
                  <h2 className='text-lg font-bold'>Order Details</h2>
                  <button
                    className='text-gray-600 hover:text-gray-800'
                    onClick={closeModal}
                  >
                    <FaTimes />
                  </button>
                </div>
                <p>
                  <strong>Order Date:</strong>{' '}
                  {new Date(selectedOrder.createdAt).toLocaleString()}
                </p>
                <table className='min-w-full mt-4'>
                  <tbody>
                    {selectedOrder.products.map((item) => (
                      <tr key={item.product._id}>
                        <td>
                          <img
                            src={item.product.images}
                            alt={item.product.name}
                            className='w-10 h-10 rounded-full'
                          />
                        </td>
                        <td>
                          {item.product.name} - Quantity: {item.quantity} -
                          Price: {item.product.price} cfh
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan='2'
                        className='text-center text-lg pt-6'
                      >
                        <strong>Total: {selectedOrder.totalAmount} cfh</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderTable;
