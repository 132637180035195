import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../components/products/ProductCard';
import FetchData from '../../hooks/fetchData';
import Loader from '../../components/Loaders/Loader';
import { BASE_URL } from '../../config';

const Products = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // for loader
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const { data } = FetchData(`${BASE_URL}/products`);

  const products = data;

  if (!products) {
    return null;
  }

  // for sorting
  const sortedProducts = products
    .filter((product) => product.deals === 'yes')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const displayedProducts = sortedProducts.slice(0, 14);

  // for view all
  const handleViewAll = () => {
    navigate('/view-all', { state: { dealType: 'yes' } });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className='p-4 font-bold lg:text-[24px] flex justify-between pt-12'>
            <p className='lg:p-8'>Deals</p>
            <div
              className='flex items-center gap-2 cursor-pointer'
              onClick={handleViewAll}
            >
              <p className='lg:text-[24px]'>View All</p>
              <i
                className='fa fa-arrow-right'
                aria-hidden='true'
              ></i>
            </div>
          </div>
          <div className='  lg:pl-20 flex lg:flex-wrap md:flex-wrap  overflow-x-auto scrollbar-hide sm:overflow-x-visible   lg:gap-4 md:gap-4 '>
            {displayedProducts.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
