import { Link } from 'react-router-dom';
import { authContext } from '../../context/authContext.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { FaCartPlus, FaHeart, FaSearch, FaTh } from 'react-icons/fa';
import Logo from '../../assets/images/logos.png';

function Header() {
  const [showLinks, setShowLinks] = useState(false);
  const { user, role, token } = useContext(authContext);
  const { dispatch } = useContext(authContext);
  const navigate = useNavigate();

  // Get user image
  const [formData, setFormData] = useState({
    name: user?.name || '',
    image: user?.image || null,
  });

  // Set user image
  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name || '',
        image: user?.image || null,
      });
    }
  }, [user]);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const handleLinkClick = () => {
    setShowLinks(false);
  };

  // Scroll to top
  useEffect(() => {
    const handleScroll = () => {
      if (showLinks) {
        setShowLinks(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showLinks]);

  // Logout
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    sessionStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    setShowLinks(false);
    navigate('/');
    toast.success('Logged out successfully');
    window.location.reload();
  };

  // Dashboard Navigation
  const handleDashboardNavigation = () => {
    if (role === 'customer') {
      navigate('/my-account');
    } else if (role === 'admin') {
      navigate('/admin');
    } else {
      navigate('/home');
    }
    handleLinkClick();
  };

  return (
    <div>
      <div className='main'>
        <div className='fixed top-0 z-50 navbar flex justify-center h-10 bg-black shadow-gray-50 text-white lg:px-30 px-[5px] w-full'>
          {/* Banner */}
          <div className='flex justify-between lg:gap-10 md:gap-[20px] gap-8 lg:text-base md:text-[14px] text-[10px] items-center'>
            <p className='text-center md:text-left'>
              Summer Sale For All Food Items And Free Express Delivery - OFF
              50%!
            </p>
            <Link to=''>Shop Now</Link>
            <select className='bg-black text-white border-none'>
              <option>English</option>
              <option>Tamil</option>
            </select>
          </div>
        </div>

        {/* Header */}
        <div className='header fixed z-10 top-0 w-full h-[130px] bg-white shadow-md lg:px-5 md:px-5 px-4'>
          <div className='search flex items-center justify-between lg:pt-10 md:pt-12 pt-14 md:gap-4 lg:gap-5 gap-3'>
            <Link
              to='/'
              className='w-[80px] md:w-[100px] lg:w-[110px]'
            >
              <img
                src={Logo}
                alt='logo'
                className='lg:h-auto md:h-auto h-[80px] lg:w-full md:w-full object-cover'
              />
            </Link>

            <div className='rounded-md px-4 bg-gray-100 label lg:w-[85%] md:w-[70%] w-[60%] border-none items-center flex justify-between scroll mr-2'>
              <FaSearch className='text-black' />
              <input
                type='search'
                placeholder='Search Product'
                className='px-4 bg-transparent focus:outline-none md:w-[100%] lg:w-[100%] h-[45px] items-center'
              />
            </div>

            {/* Show mobile menu icon only before login */}
            {!token && (
              <div className='dropdown lg:hidden md:hidden block text-[35px] justify-end '>
                <i
                  className='fa-solid fa-list cursor-pointer'
                  onClick={toggleLinks}
                ></i>
              </div>
            )}

            <div className='shop lg:text-[30px] md:text-[25px] hidden lg:blog gap-12 md:gap-5 md:flex lg:flex'>
              <Link to='/wishlist'>
                <FaHeart className='lg:h-[43px] md:h-[35px]' />
              </Link>
              <Link to='/category'>
                <FaTh className='lg:h-[43px] md:h-[35px]' />
              </Link>
              <Link to='/cart'>
               <FaCartPlus className='lg:h-[43px] md:h-[35px]' />
              </Link>
            </div>

            <div>
              {token && user ? (
                <div className='user text-black'>
                  <div
                    onClick={toggleLinks}
                    className='flex gap-4 items-center cursor-pointer'
                  >
                    <img
                      src={
                        formData.image || 'http://www.gravatar.com/avatar/?d=mp'
                      }
                      alt='profile'
                      className='rounded-full lg:h-[50px] lg:w-[55px] md:h-[50px] md:w-[50px] h-[50px] w-[55px] object-cover border-width-2 border-black'
                    />
                  </div>
                </div>
              ) : (
                <Link to='/login'>
                  <button className='bg-primary px-6 py-2 text-white font-semibold rounded-md hidden lg:block md:block'>
                    Login
                  </button>
                </Link>
              )}
            </div>
          </div>

          {/* Show links only before login */}
          {!token && showLinks && (
            <div className='dropdown-links flex flex-col gap-3 mt-2 bg-black text-white p-4 rounded shadow-md absolute top-[93%] right-0 '>
              <Link
                to='/wishlist'
                onClick={handleLinkClick}
              >
                Wishlist
              </Link>
              <Link
                to='/category'
                onClick={handleLinkClick}
              >
                All Categories
              </Link>
              <Link
                to='/cart'
                onClick={handleLinkClick}
              >
                Cart
              </Link>
              <Link
                to='/login'
                onClick={handleLinkClick}
              >
                <button className='bg-primary px-6 py-2 text-white font-semibold rounded-md'>
                  Login
                </button>
              </Link>
            </div>
          )}

          {/* Show links only after login */}
          {token && showLinks && (
            <div className='dropdown-links flex flex-col gap-3 mt-2 bg-black text-white p-4 rounded shadow-md absolute top-[93%] right-0 '>
              <button
                className='pr-5'
                onClick={handleDashboardNavigation}
              >
                My-Account
              </button>
              <Link
                to='/wishlist'
                onClick={handleLinkClick}
                className='lg:hidden md:hidden'
              >
                Wishlist
              </Link>
              <Link
                to='/category'
                onClick={handleLinkClick}
                className='lg:hidden md:hidden'
              >
                All Categories
              </Link>
              <Link
                to='/cart'
                onClick={handleLinkClick}
                className='lg:hidden md:hidden'
              >
                Cart
              </Link>
              <button
                className='bg-primary px-6 py-2 text-white font-semibold rounded-md'
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
