import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProductCard from '../../components/products/ProductCard';
import FetchData from '../../hooks/fetchData';
import { BASE_URL } from '../../config';
import Loader from '../Loaders/Loader';

const AllOffers = () => {
  const location = useLocation();
  const [dealType, setDealType] = useState();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const { data, loading } = FetchData(`${BASE_URL}/products`);

  // setProducts(data);
  useEffect(() => {
    if (data) {
      setProducts(data);
    }
  }, [data]);

  useEffect(() => {
    if (location.state) {
      if (location.state.dealType === 'no') {
        setDealType('no');
      } else {
        setDealType('yes');
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (products) {
      if (dealType === 'yes') {
        setFilteredProducts(
          products.filter((product) => product.deals === dealType)
        );
      } else {
        setFilteredProducts(
          products.sort(
            (a, b) => (a.deals === 'yes' ? 0 : 1) - (b.deals === 'yes' ? 0 : 1)
          )
        );
      }
    }
  }, [products, dealType]);

  const headingText = dealType === 'yes' ? 'All Offers' : 'All New Products';

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='pt-32 h-screen'>
          <h1 className='text-2xl font-bold p-4'>{headingText}</h1>
          <div className='flex flex-wrap  justify-start lg:px-0 md:px-0 px-6  gap-4'>
            {filteredProducts.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                loading={loading}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllOffers;
