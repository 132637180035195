import React, { useState, useEffect } from 'react';
import { BASE_URL, token } from '../../config';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import CartEmpty from '../../assets/images/no cart.jpg';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loaders/Loader';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Cart() {
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const navigate = useNavigate();

  // Fetch All Carts
  const fetchCart = async () => {
    try {
      const response = await fetch(`${BASE_URL}/cart`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cart');
      }

      const data = await response.json();
      setCart(data);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Remove From Cart
  const removeItem = async () => {
    if (!productToDelete) return;
    try {
      const response = await fetch(`${BASE_URL}/cart/${productToDelete}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to remove item');
      }

      await fetchCart();
      toast.success('Item removed successfully');
      closeModal(); // Close the modal after removing the item
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Clear Cart
  const clearCart = async () => {
    try {
      if (window.confirm('Are you sure you want to clear your cart?')) {
        const response = await fetch(`${BASE_URL}/cart`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to clear cart');
        }
      }

      // Re-fetch the cart to reflect the empty cart state
      await fetchCart();
      toast.success('Cart cleared successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Update Cart
  const updateCart = async (productId, quantity) => {
    try {
      const response = await fetch(`${BASE_URL}/cart/${productId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ quantity }),
      });

      if (!response.ok) {
        throw new Error('Failed to update cart');
      }

      await fetchCart();
      toast.success('Cart updated successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchCart();
  }, []);

  const openModal = (productId) => {
    setProductToDelete(productId); // Store the product ID to be deleted
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setProductToDelete(null); // Clear the product ID
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error loading cart: {error.message}</p>;
  }

  // Render Empty Cart
  if (!cart || !Array.isArray(cart.items) || cart.items.length === 0) {
    return (
      <div className='flex flex-col justify-center h-[100vh] w-[100%] items-center'>
        <div className='lg:w-[30%] md:w[40%] w-[100%] '>
          <img
            src={CartEmpty}
            alt='Empty cart'
            className='w-full h-full'
          />
        </div>
        <p className='text-lg font-semibold flex gap-4 pt-10 flex-col justify-center items-center'>
          Your Cart is empty
          <span className='text-primary underline'>
            <Link to='/'>Click To Continue with Liquor House</Link>
          </span>
        </p>
      </div>
    );
  }

  const totalPrice = cart.items.reduce(
    (total, item) => total + (item.product.price * item.quantity || 0),
    0
  );

  const handleBuyNow = () => {
    navigate('/checkoutcart', { state: { cart: cart.items } });
  };

  return (
    <div
      className='container mx-auto pt-[250px] p-4 min-h-screen h-[100vh] overflow-y-scroll'
      style={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {/* Cart Table */}
      <table className='min-w-full bg-white rounded-lg shadow-md mb-4'>
        <thead>
          <tr>
            <th className='p-4 text-left'>Item & Description</th>
            <th className='p-4 text-center'>Qty</th>
            <th className='p-4 text-center'>Rate</th>
            <th className='p-4 text-center'>Amount</th>
            <th className='p-4'>Remove</th>
          </tr>
        </thead>
        <tbody>
          {cart.items.map((item) => (
            <tr
              key={item.product._id}
              className='border-t border-gray-200'
            >
              <td className='p-4'>
                <div className='flex items-center'>
                  <img
                    src={
                      (item.product.images && item.product.images[0]) ||
                      '/path/to/default/image.jpg'
                    }
                    alt={item.product.name}
                    className='w-12 h-12 object-cover rounded-full mr-4'
                  />
                  <span className='text-lg font-medium'>
                    {item.product.name}
                  </span>
                </div>
              </td>
              <td className='p-4 text-center'>
                <div className='flex items-center justify-center space-x-2'>
                  <button
                    className='w-8 h-8 flex justify-center items-center bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 rounded'
                    onClick={() =>
                      updateCart(item.product._id, item.quantity - 1)
                    }
                  >
                    –
                  </button>
                  <span className='w-8 h-8 flex justify-center items-center text-lg'>
                    {item.quantity}
                  </span>
                  <button
                    className='w-8 h-8 flex justify-center items-center bg-primary text-white hover:bg-primary rounded'
                    onClick={() =>
                      updateCart(item.product._id, item.quantity + 1)
                    }
                  >
                    +
                  </button>
                </div>
              </td>
              <td className='p-4 text-center'>${item.product.price}</td>
              <td className='p-4 text-center'>
                ${item.product.price * item.quantity}
              </td>
              <td className='p-4 text-center'>
                <button
                  className='text-red-500 hover:text-red-700'
                  onClick={() => openModal(item.product._id)} // Open modal on click
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Total Price */}
      <div className='bg-white p-4 flex justify-between items-center rounded-lg mt-4'>
        <div className='text-lg font-semibold'>Total: ${totalPrice}</div>

        <div className='flex justify-between gap-4'>
          <div>
            <button
              className='px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary'
              onClick={clearCart}
            >
              Clear All
            </button>
          </div>
          <div>
            {cart.items.length > 0 && (
              <button
                className='px-4 py-2 bg-primary text-white font-semibold rounded hover:bg-primary'
                onClick={handleBuyNow}
              >
                Buy Now
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
      >
        <div className='bg-white p-6 rounded shadow-lg max-w-sm mx-auto'>
          {productToDelete && (
            <>
              <div className='flex items-center mb-4'>
                <img
                  src={
                    cart.items.find(
                      (item) => item.product._id === productToDelete
                    )?.product.images[0] || '/path/to/default/image.jpg'
                  }
                  alt='Product'
                  className='w-16 h-16 rounded-full mr-4'
                />
                <span className='text-lg font-semibold'>
                  {
                    cart.items.find(
                      (item) => item.product._id === productToDelete
                    )?.product.name
                  }
                </span>
              </div>
              <p className='mb-4'>Are you sure want to delete this item?</p>
              <div className='flex justify-between'>
                <button
                  onClick={removeItem}
                  className='bg-red-500 text-white px-4 py-2 rounded'
                >
                  Delete
                </button>
                <button
                  onClick={closeModal}
                  className='bg-gray-500 text-white px-4 py-2 rounded'
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Cart;
