import React, { useState, useEffect, useRef } from 'react';
import FetchData from '../../hooks/fetchData';
import { BASE_URL, token } from '../../config';
import { FaEdit, FaEye, FaSearch, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import ProductForm from '../admin/Productedit';

Modal.setAppElement('#root');

const ProductTable = () => {
  const [visibleDetails, setVisibleDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false); // For edit modal
  const [productToDelete, setProductToDelete] = useState(null);
  const [productToEdit, setProductToEdit] = useState(null); // For editing
  const [data, setData] = useState([]);
  const { data: productData } = FetchData(`${BASE_URL}/products`);
  const [search, setSearch] = useState('');
  const inputRef = useRef(null);

  // For edit modal
  useEffect(() => {
    if (productData) {
      setData(productData);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [productData]);

  // For delete modal
  const handleDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await fetch(
        `${BASE_URL}/products/${productToDelete._id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      toast.success('Product deleted successfully!');
      setData((prevData) =>
        prevData.filter((product) => product._id !== productToDelete._id)
      );
      closeModal();
    } catch (error) {
      console.error('Error deleting product:', error.message);
    }
  };

  // Modal
  const openModal = (product) => {
    setProductToDelete(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setProductToDelete(null);
  };

  // Toggle details
  const toggleDetails = (id) => {
    setVisibleDetails(visibleDetails === id ? null : id);
  };

  // Handle scroll
  const handleScroll = () => setVisibleDetails(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // For edit modal
  const openEditModal = (product) => {
    setProductToEdit(product);
    setIsEditOpen(true);
  };

  // For edit modal
  const closeEditModal = () => {
    setIsEditOpen(false);
    setProductToEdit(null);
  };

  // Filter the data based on search input
  const filteredData = data.filter((product) =>
    product.name.toLowerCase().includes(search.toLowerCase())
  );

  // Check if filtered data is empty
  const isNotFound = filteredData.length === 0 && search.trim() !== '';

  if (!data.length) {
    return <p>Loading...</p>;
  }

  return (
    <div className='w-full flex items-center justify-center'>
      {/* Table */}
      <div className='w-full  overflow-auto'>
        <div className='rounded-md px-4 bg-white label lg:w-[100%] md:w-[70%] border-none items-center flex justify-between mb-5'>
          <FaSearch />
          <input
            type='search'
            ref={inputRef}
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Search Product'
            className='px-4 bg-transparent focus:outline-none md:w-[100%] lg:w-[100%] h-[45px] items-center'
          />
        </div>

        {isNotFound ? (
          <div className='flex justify-center items-center h-32'>
            <p className='text-red-500 text-lg font-bold'>No products found</p>
          </div>
        ) : (
          <table className='min-w-full w-full table-auto border-collapse'>
            {/* Table header */}
            <thead className='sticky top-0 bg-white shadow'>
              <tr className='border h-[70px] text-gray-600 uppercase text-sm leading-normal'>
                <th className='py-3 px-6 text-left'>Product</th>
                <th className='py-3 px-6 text-left hidden lg:table-cell'>
                  Price
                </th>
                <th className='py-3 px-6 text-left hidden md:table-cell'>
                  Quantity
                </th>
                <th className='py-3 px-6 text-left hidden lg:table-cell'>
                  Discount
                </th>
                <th className='py-3 px-6 text-left hidden lg:table-cell'>
                  Expired Date
                </th>
                <th className='py-3 px-6 text-left hidden md:table-cell'>
                  Stock
                </th>
                <th className='py-3 px-6 text-center'>Action</th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className='text-black font-bold text-md'>
              {filteredData.map((product, index) => (
                <React.Fragment key={product._id}>
                  <tr
                    className={`border-b border-gray-200 hover:bg-gray-100 ${
                      index % 2 ? 'bg-gray-50' : ''
                    }`}
                  >
                    <td className='py-3 px-6 text-left flex items-center'>
                      <img
                        src={product.images}
                        alt={product.name}
                        className='w-10 h-10 rounded-full mr-3 object-contain bg-black'
                      />
                      <span>{product.name}</span>
                    </td>
                    <td className='py-3 px-6 text-left hidden md:table-cell'>
                      {product.price}
                    </td>
                    <td className='py-3 px-6 text-left hidden md:table-cell'>
                      {product.stock}
                    </td>
                    <td className='py-3 px-6 text-left hidden lg:table-cell'>
                      {product.discount || '--'}
                    </td>
                    <td className='py-3 px-6 text-left hidden lg:table-cell'>
                      {new Date(product.expiredDate).toLocaleDateString()}
                    </td>
                    <td className='py-3 px-6 text-left hidden lg:table-cell'>
                      <span
                        className={`bg-${
                          product.stock > 0 ? 'green' : 'red'
                        }-200 text-${
                          product.stock > 0 ? 'green' : 'red'
                        }-600 py-1 px-3 rounded-full text-xs`}
                      >
                        {product.stock > 0 ? 'In stock' : 'Out of stock'}
                      </span>
                    </td>
                    <td className='p-4'>
                      <div className='flex justify-center space-x-2'>
                        <button
                          className='text-black lg:hidden'
                          onClick={() => toggleDetails(product._id)}
                        >
                          <FaEye />
                        </button>
                        <button
                          className='text-green-500'
                          onClick={() => openEditModal(product)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className='text-red-500'
                          onClick={() => openModal(product)}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                  {visibleDetails === product._id && (
                    <tr className='bg-gray-100'>
                      <td
                        colSpan='7'
                        className='py-3 px-6'
                      >
                        <div className='flex flex-col md:hidden'>
                          <p className='font-semibold'>
                            Price: {product.price}
                          </p>
                          <p className='font-semibold'>
                            Stock:
                            <span
                              className={`bg-${
                                product.stock > 0 ? 'green' : 'red'
                              }-200 text-${
                                product.stock > 0 ? 'green' : 'red'
                              }-600 py-1 px-3 rounded-full text-xs`}
                            >
                              {product.stock > 0 ? 'In stock' : 'Out of stock'}
                            </span>
                          </p>
                          <p className='font-semibold'>
                            Quantity: {product.stock}
                          </p>
                          <p className='font-semibold'>
                            Discount: {product.discount}%
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Modal for Delete Confirmation */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'
        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
      >
        <div className='bg-white p-6 rounded shadow-lg max-w-sm mx-auto'>
          {productToDelete && (
            <>
              <div className='flex items-center mb-4'>
                <img
                  src={productToDelete.images}
                  alt={productToDelete.name}
                  className='w-16 h-16 rounded-full  mr-4'
                />
                <p className='text-lg font-semibold'>{productToDelete.name}</p>
              </div>
              <p className='mb-4'>Are you sure want to delete this product?</p>
              <div className='flex justify-between'>
                <button
                  onClick={handleDelete}
                  className='bg-red-500 text-white px-4 py-2 rounded'
                >
                  Delete
                </button>
                <button
                  onClick={closeModal}
                  className='bg-gray-500 text-white px-4 py-2 rounded'
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>

      {/* Modal for Edit Product */}
      <Modal
        isOpen={isEditOpen}
        onRequestClose={closeEditModal}
        className='  inset-0 flex items-center justify-center h-[100vh] '
        overlayClassName='fixed inset-0 bg-black bg-opacity-50'
      >
        <div className='bg-white p-6 rounded shadow-md lg:w-[46%] md:w-[70%] w-[60%]'>
          {productToEdit && (
            <ProductForm
              product={productToEdit}
              onClose={closeEditModal}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ProductTable;
