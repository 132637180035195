import React from 'react';
import { Audio } from 'react-loader-spinner';

function Loader() {
  return (
    <div>
      <div className='flex justify-center items-center h-[100vh]'>
        <Audio
          height='80'
          width='80'
          radius='9'
          color='red'
          ariaLabel='loading'
          wrapperStyle
          wrapperClass
        />
      </div>
    </div>
  );
}

export default Loader;
