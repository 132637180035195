import React, { useState, useEffect, useContext, useRef } from 'react'; // Added useRef and useContext
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faShoppingCart,
  faUsers,
  faUser,
  faCog,
  faBell,
  faSignOutAlt,
  faUserCircle,
  faBars,
  faClipboardList,
  faImages,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import ProductTable from './productlist';
import Addproduct from './Addproduct';
import Categorylist from './categorylist';
import Orderdetails from './orderdetails';
import Users from './users';
import Maindashboard from './maindashboard';
import AdminProfile from './profile';
import { authContext } from '../../context/authContext';
import { FaSearch } from 'react-icons/fa';

const AdminPanel = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState('Dashboard');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Ref to track the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Ref to track the sidebar
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Update search results based on the input
    const results = getSuggestions(term);
    setSearchResults(results);
  };

  // Get suggestions based on the input
  const getSuggestions = (term) => {
    const pages = [
      { label: 'Dashboard', page: 'Dashboard' },
      { label: 'Add Product', page: 'Addproduct' },
      { label: 'Product List', page: 'ProductList' },
      { label: 'Categories', page: 'Categories' },
      { label: 'Order List', page: 'OrderList' },
      { label: 'Users', page: 'AllUsers' },
      { label: 'Gallery', page: 'Gallery' },
      { label: 'Settings', page: 'Settings' },
    ];
    return pages.filter((page) =>
      page.label.toLowerCase().includes(term.toLowerCase())
    );
  };

  // Handle suggestion click
  const handleSuggestionClick = (page) => {
    setActivePage(page);
    setSearchTerm('');
    setSearchResults([]);
  };

  // Render the active page
  const renderActivePage = () => {
    switch (activePage) {
      case 'Dashboard':
        return <Maindashboard />;
      case 'ProductList':
        return <ProductTable />;
      case 'Addproduct':
        return <Addproduct />;
      case 'Categories':
        return <Categorylist />;
      case 'OrderList':
        return <Orderdetails />;
      case 'AllUsers':
        return <Users />;
      case 'Gallery':
        return (
          <div>
            Welcome to the Gallery! Add and organize your images and media files
            here.
          </div>
        );
      case 'Settings':
        return <AdminProfile />;
      default:
        return (
          <div>
            Welcome to the Dashboard! Here, you can view your overall statistics
            and recent activities.
          </div>
        );
    }
  };

  return (
    <div className='flex h-screen bg-gray-100'>
      {/* Sidebar */}
      <aside
        onMouseEnter={() => setIsSidebarOpen(true)}
        onMouseLeave={() => setIsSidebarOpen(false)}
        className={`${
          isSidebarOpen ? 'w-64' : 'w-16'
        } bg-white shadow-md transition-all duration-300`}
      >
        <div className='p-4 flex items-center justify-between'>
          <span className={`font-bold text-xl ${!isSidebarOpen && 'hidden'}`}>
            <Link to='/'>Liquor House</Link>
          </span>
          <button
            className='text-gray-600'
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>

        {/* Sidebar items */}
        <nav className='mt-4'>
          <SidebarItem
            icon={faTachometerAlt}
            label='Dashboard'
            onClick={() => setActivePage('Dashboard')}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarItem
            icon={faShoppingCart}
            label='Ecommerce'
            submenu={[
              {
                label: 'Add Product',
                onClick: () => setActivePage('Addproduct'),
              },
              {
                label: 'Product List',
                onClick: () => setActivePage('ProductList'),
              },
            ]}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarItem
            icon={faUsers}
            label='Categories'
            onClick={() => setActivePage('Categories')}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarItem
            icon={faClipboardList}
            label='Order'
            onClick={() => setActivePage('OrderList')}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarItem
            icon={faUser}
            label='User'
            onClick={() => setActivePage('AllUsers')}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarItem
            icon={faImages}
            label='Gallery'
            onClick={() => setActivePage('Gallery')}
            isSidebarOpen={isSidebarOpen}
          />
          <SidebarItem
            icon={faCog}
            label='Setting'
            onClick={() => setActivePage('Settings')}
            isSidebarOpen={isSidebarOpen}
          />
        </nav>
      </aside>

      {/* Main content */}
      <div className='flex-1 flex flex-col'>
        <TopBar
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          searchResults={searchResults}
          handleSuggestionClick={handleSuggestionClick}
        />
        <main className='flex-1 p-6 bg-gray-100 overflow-auto'>
          {renderActivePage()}
        </main>
      </div>
    </div>
  );
};

// SidebarItem component
const SidebarItem = ({ icon, label, submenu, onClick, isSidebarOpen }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className={`flex items-center p-4 text-gray-700 hover:bg-gray-200 cursor-pointer ${
          !isSidebarOpen && 'justify-center'
        }`}
        onClick={() => {
          setOpen(!open);
          if (onClick) onClick();
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          className='w-5 h-5'
        />
        {isSidebarOpen && <span className='ml-4'>{label}</span>}
        {submenu && isSidebarOpen && (
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`w-4 h-4 ml-auto transition-transform ${
              open ? 'transform rotate-180' : ''
            }`}
          />
        )}
      </div>
      {submenu && open && isSidebarOpen && (
        <div className='ml-8'>
          {submenu.map((item, index) => (
            <div
              key={index}
              className='p-2 text-gray-600 hover:bg-gray-200 cursor-pointer'
              onClick={item.onClick}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// TopBar component
const TopBar = ({
  searchTerm,
  handleSearchChange,
  searchResults,
  handleSuggestionClick,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const selectedPage = searchResults[0]; // Navigate to the first suggestion
      if (selectedPage) {
        handleSuggestionClick(selectedPage.page);
      }
    }
  };

  return (
    <header className='bg-white shadow p-4 flex justify-between items-center'>
      <div className='relative rounded-md px-4 bg-white label lg:w-[90%] md:w-[70%] border-none flex'>
        <input
          type='text'
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          className='px-4 bg-zinc-100 focus:outline-none md:w-[100%] lg:w-[100%] h-[45px] pr-10'
          placeholder='Search here...'
        />
        <FaSearch className='absolute right-8 top-1/2 transform -translate-y-1/2' />

        {searchResults.length > 0 && (
          <div className='absolute z-10 bg-white border border-gray-300 w-full mt-1 rounded-lg shadow-lg'>
            {searchResults.map((item, index) => (
              <div
                key={index}
                className='p-2 hover:bg-gray-200 cursor-pointer'
                onClick={() => handleSuggestionClick(item.page)}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className='flex items-center space-x-4'>
        <FontAwesomeIcon
          icon={faBell}
          className='w-5 h-5 text-gray-600'
        />
        <FontAwesomeIcon
          icon={faCog}
          className='w-5 h-5 text-gray-600'
        />
        <Profile />
      </div>
    </header>
  );
};

// Profile component
const Profile = () => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const { dispatch, user } = useContext(authContext);
  const navigate = useNavigate();

  // Handle profile click
  const handleProfileClick = () => {
    setShowMenu(!showMenu);
  };

  // Close the menu when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  // Close the menu when clicking outside
  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  // Logout function
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    sessionStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');

    navigate('/');
    toast.success('Logged out successfully');
  };

  return (
    <div
      className='relative'
      ref={menuRef}
    >
      <div
        onClick={handleProfileClick}
        className='flex items-center gap-4 cursor-pointer'
      >
        <p>{user.name}</p>
        <img
          src={user.photo || 'http://www.gravatar.com/avatar/?d=mp'}
          alt='Profile'
          className='w-8 h-8 rounded-full'
        />
        <FontAwesomeIcon
          icon={faChevronDown}
          className='w-6 h-10 mr-4  text-gray-600'
        />
      </div>

      {showMenu && (
        <div className='absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg'>
          <div className='p-2 text-gray-600 hover:bg-gray-100 cursor-pointer'>
            <FontAwesomeIcon
              icon={faUserCircle}
              className='mr-2'
            />
            Profile
          </div>

          <div
            className='p-2 text-gray-600 hover:bg-primary hover:text-white cursor-pointer'
            onClick={handleLogout}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className='mr-2'
            />
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
