import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaHeart, FaPlus } from 'react-icons/fa';
import { BiHeart } from 'react-icons/bi';
import { BASE_URL, token } from '../../config';
import Loader from '../Loaders/Loader';

const ProductCard = ({ product, displayMode, loading }) => {
  const { _id, images, name, price, discount, rating, deals, category } =
    product;
  const [isInWishlist, setIsInWishlist] = useState(false);

  const numericPrice = parseFloat(price) || 0;
  const numericDiscount = parseFloat(discount) || 0;

  // Calculate discount price
  const calculateDiscountPrice = (price, discount) => {
    return discount > 0
      ? (price - (price * discount) / 100).toFixed(2)
      : price.toFixed(2);
  };

  const discount_price = calculateDiscountPrice(numericPrice, numericDiscount);

    // Check if the product is already in the wishlist
  useEffect(() => {
    if (token === null) {
      return setIsInWishlist(false);
    } else {
      const fetchWishlist = async () => {
        try {
          const response = await fetch(`${BASE_URL}/wishlist`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          const { products } = await response.json();
          const isInWishlist = products.some((item) => item._id === _id);
          setIsInWishlist(isInWishlist);
        } catch (error) {
          console.error('Error fetching wishlist:', error);
        }
      };
      fetchWishlist();
    }
  }, [_id, isInWishlist]);

  // wishlist
  const [formData, setFormData] = useState({
    productId: _id,
    quantity: 1, // default quantity to 1
  });

  // Add to cart
  const handleAddToCart = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch(`${BASE_URL}/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // send the token for authentication
        },
        body: JSON.stringify({
          product: formData.productId,
          quantity: formData.quantity,
        }),
      });

      const { message } = await res.json();
      if (!res.ok) throw new Error(message);
      toast.success('Product added to cart!');
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  // Add to wishlist
  const handleWishlistSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch(`${BASE_URL}/wishlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (res.ok) {
        setIsInWishlist(true);
      }

      const { message } = await res.json();

      if (!res.ok) throw new Error(message);
      toast.success('Product added to wishlist!');
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: null,
      }));
    } catch (error) {
      console.log(error.message);
      if (error.message === 'Unauthorized: Invalid token') {
        toast.error('Please login to add to wishlist');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  // Remove from wishlist
  const handleRemoveFromWishlist = async (event) => {
    event.preventDefault();
    try {
      const res = await fetch(`${BASE_URL}/wishlist/${formData.productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        setIsInWishlist(false);
      }

      const { message } = await res.json();
      if (!res.ok) throw new Error(message);
      toast.success('Product removed from wishlist!');

      setFormData((prevFormData) => ({
        ...prevFormData,
        images: null,
      }));
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='lg:px-3 px-3'>
          {displayMode === 'simple' ? (
            <div className='flex flex-col items-center justify-center px-4'>
              <div className='p-2 w-full h-[120px] lg:h-[150px] md:w-[200px] md:h-[150px] bg-black rounded-lg overflow-hidden'>
                <img
                  src={images}
                  alt={name}
                  className='w-full h-full object-contain'
                />
              </div>
              <div>
                <p className='text-xs sm:text-lg text-gray-600'>{category}</p>
              </div>
            </div>
          ) : (
            <div className=' flex gap-4 py-5 sm:flex-row lg:flex-col lg:items-start'>
              <div className='  p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex flex-col cursor-pointer w-[150px] sm:w-auto relative'>
                {numericDiscount > 0 && (
                  <div className='absolute top-1 left-1 bg-primary text-white text-xs px-2 py-1 rounded-tr-md rounded-bl-md'>
                    {numericDiscount}%
                  </div>
                )}
                <div className='lg:h-[220px] lg:w-[180px]'>
                  <Link to={`/products/${_id}`}>
                    <div className='p-2 lg:w-[180px] h-[120px] lg:h-[150px] md:w-[200px] md:h-[150px] bg-black rounded-t-lg overflow-hidden flex items-center justify-center'>
                      <img
                        src={images}
                        alt={name}
                        className='w-full h-full object-contain'
                      />
                    </div>
                    <div className='mt-4 text-left lg:text-lg text-[14px] md:text-lg'>
                      {deals === 'yes' && (
                        <div className='flex gap-2 sm:gap-5'>
                          <p className='text-sm sm:text-[18px] font-bold text-white bg-primary w-20 sm:w-24 flex items-center text-center rounded-[5px] px-1'>
                            ${discount_price}
                          </p>
                          {numericDiscount > 0 && (
                            <p className='text-xs sm:text-lg text-gray-600 mt-1 line-through'>
                              ${numericPrice.toFixed(2)}
                            </p>
                          )}
                        </div>
                      )}
                      <p className='text-xs sm:text-lg text-gray-600 mt-1'>
                        {name}
                      </p>
                      {rating && (
                        <p className='text-xs sm:text-sm text-gray-600 mt-1'>
                          {rating}
                        </p>
                      )}
                    </div>
                  </Link>
                </div>

                <div className='flex justify-between mt-2 sm:mt-3 w-full text-[20px] sm:text-[25px] px-2'>
                  {isInWishlist ? (
                    <button
                      title='Remove from wishlist'
                      className='text-gray-800 hover:text-primary'
                      aria-label='Remove from wishlist'
                      onClick={handleRemoveFromWishlist}
                    >
                      <FaHeart className='text-red-700' />
                    </button>
                  ) : (
                    <button
                      title='Add to wishlist'
                      className='text-gray-800 hover:text-primary'
                      aria-label='Add to wishlist'
                      onClick={handleWishlistSubmit}
                    >
                      <BiHeart className='text-3xl' />
                    </button>
                  )}
                  <button
                    title='Add to cart'
                    className='text-gray-800 hover:text-primary'
                    aria-label='Add to cart'
                    onClick={handleAddToCart}
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductCard;
