import React, { useState } from 'react';
import { BASE_URL, token } from '../../config';
import {FaTimes} from 'react-icons/fa';
import { toast } from 'react-toastify';
import uploadImage from '../../utils/cloudinary';

const ProductForm = ({ product, onClose }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState(
    product.images || null
  ); // Initialize with product image

  // State for form data
  const [formData, setFormData] = useState({
    name: product.name,
    price: product.price,
    images: product.images || null, // Ensure this is the same key as used later
    description: product.description,
    weight: product.weight,
    ingredients: product.ingredients,
    category: product.category,
    deals: product.deals,
    stock: product.stock,
    discount: product.discount,
    expiredDate: product.expiredDate,
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image upload
  const handleImageUpload = async (event) => {
    const files = event.target.files[0];
    if (!files) return;

    // Immediately set the selected image for preview
    const imageUrl = URL.createObjectURL(files);
    setUploadedImageUrl(imageUrl);

    try {
      const data = await uploadImage(files);
      console.log('Uploaded Image Data:', data); // Log response from Cloudinary
      setFormData((prevData) => ({
        ...prevData,
        images: data.url, // Update the image URL in formData
      }));
    } catch (error) {
      console.error('Image upload failed:', error);
      toast.error('Image upload failed');
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/products/${product._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to update product');
      }

      toast.success('Product updated successfully!');
      onClose(); // Close the modal after successful update
    } catch (error) {
      console.error('Error updating product:', error);
      toast.error('Failed to update product');
    }
    window.location.reload();
  };

  return (
    <div className='w-[100%]'>
      <form onSubmit={handleSubmit}>
        <div className='flex justify-between mb-8 items-center '>
          <div>
            <h2 className='text-xl font-bold'>Edit Product</h2>
          </div>
          <div className='text-[25px]'>
            <button
              type='button'
              onClick={onClose}
            >
              <FaTimes />
            </button>
          </div>
        </div>

        {/* Form inputs */}
        <div className='flex w-[100%] justify-between'>
          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Name
            </label>
            <input
              type='text'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
              required
            />
          </div>
          <div className='flex pt-1 '>
            <img
              src={uploadedImageUrl || formData.images} // Display the uploaded or existing image
              alt={formData.name}
              className='w-24 h-20 rounded-md mr-2 object-contain bg-black cursor-pointer'
              onClick={() => document.getElementById('fileInput').click()}
            />
          </div>
          <input
            type='file'
            id='fileInput'
            className='hidden'
            accept='image/*'
            onChange={handleImageUpload}
          />
        </div>

        <div className='flex justify-between'>
          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Price
            </label>
            <input
              type='text'
              name='price'
              value={formData.price}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px]  px-3 py-2 border rounded'
              required
            />
          </div>

          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Expired Date
            </label>
            <input
              type='date'
              name='expiredDate'
              value={formData.expiredDate}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
              required
            />
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Weight
            </label>
            <input
              type='text'
              name='weight'
              value={formData.weight}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
            />
          </div>

          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Ingredients
            </label>
            <input
              type='text'
              name='ingredients'
              value={formData.ingredients}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
              required
            />
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Category
            </label>
            <input
              type='text'
              name='category'
              value={formData.category}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
              required
            />
          </div>
          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Stock
            </label>
            <input
              type='number'
              name='stock'
              value={formData.stock}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
            />
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Deals
            </label>
            <input
              type='text'
              name='deals'
              value={formData.deals}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
              required
            />
          </div>

          <div className='flex flex-col lg:gap-2 md:gap-2 '>
            <label className='block text-sm font-medium text-gray-700 pt-2'>
              Discount
            </label>
            <input
              type='text'
              name='discount'
              value={formData.discount}
              onChange={handleInputChange}
              className='lg:w-[400px] md:w-[240px] w-[140px] px-3 py-2 border rounded'
              required
            />
          </div>
        </div>

        <div className='flex flex-col lg:gap-2 md:gap-2 '>
          <label className='block text-sm font-medium text-gray-700 pt-2'>
            Description
          </label>
          <input
            type='text'
            name='description' // Ensure this is correctly named
            value={formData.description}
            onChange={handleInputChange}
            className='w-full px-3 py-2 border rounded mb-5'
            required
          />
        </div>

        <div className='flex pt-2 lg:gap-2 md:gap-2 '>
          <button
            type='submit'
            className='px-4 py-2 w-full bg-primary text-white rounded'
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
