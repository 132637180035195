import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { authContext } from '../../context/authContext';
import Profile from './userprofile';
import Payment from './userpayment';
import Orders from './orders';
import Wishlist from './wishlist';
import FetchData from '../../hooks/fetchData';
import { BASE_URL } from '../../config';
import { FaBars, FaTimes } from 'react-icons/fa';

const UserPage = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { dispatch } = useContext(authContext);
  const navigate = useNavigate();

  // Ref to track the sidebar
  const sidebarRef = useRef(null);

  const { data: user } = FetchData(`${BASE_URL}/users/profile`);

  // FormData state
  const [formData, setFormData] = useState({
    name: user?.name || '',
    image: user?.image || null,
  });

  // Fetch user data on component mount
  useEffect(() => {
    if (user) {
      setFormData({
        name: user?.name || '',
        image: user?.image || null,
      });
    }
  }, [user]);

  // Close the sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  // Logout function
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    sessionStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    navigate('/');
    toast.success('Logged out successfully');
  };

  // Handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'Home') {
      navigate('/');
    }
    setIsSidebarOpen(false);
  };

  return (
    <div className='flex flex-col md:flex-row h-screen'>
      <div className='md:hidden p-4 flex justify-between items-center bg-gray-100 fixed top-0 left-0 w-full'>
        <h1 className='text-lg font-semibold'>{formData.name}</h1>
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 fixed md:static top-0 left-0 w-64 bg-gray-200 shadow-lg text-white h-full transform transition-transform duration-300 ease-in-out flex flex-col justify-between p-4`}
      >
        {/* User Info */}
        <div className='flex flex-col items-center py-6'>
          <div className='w-16 h-16 bg-gray-600 rounded-full mb-4'>
            <img
              src={formData.image}
              alt='Profile'
              className='w-full h-full object-cover rounded-full'
            />
          </div>
          <div className='text-black flex flex-col items-center'>
            <h2 className='text-lg font-semibold'>{formData.name}</h2>
            <p className='text-sm'>{user?.email}</p>
          </div>
        </div>

        {/* Navigation Links */}
        <ul className='flex-grow space-y-3'>
          {['profile', 'payment', 'Orders', 'wishlist', 'Home'].map((tab) => (
            <li key={tab}>
              <button
                className={`w-full text-left px-4 py-2 rounded-md ${
                  activeTab === tab ? 'bg-black text-white' : 'text-black'
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            </li>
          ))}
        </ul>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className='bg-red-600 hover:bg-red-700 text-white font-semibold py-2 rounded-md mt-4 w-full'
        >
          Log-out
        </button>
      </div>

      {/* Content Area */}
      <div className='flex-1 p-4'>
        <div className='flex items-center justify-center pt-20'>
          {activeTab === 'profile' && <Profile />}
          {activeTab === 'payment' && <Payment />}
          {activeTab === 'Orders' && <Orders />}
          {activeTab === 'wishlist' && <Wishlist />}
        </div>
      </div>
    </div>
  );
};

export default UserPage;
