import React, { useState, useEffect, useRef } from 'react';
import FetchData from '../../hooks/fetchData';
import { BASE_URL } from '../../config';
import { FaCheck, FaEye, FaSearch, FaTimes } from 'react-icons/fa';

const UserTable = () => {
  const [visibleDetails, setVisibleDetails] = useState(null);
  const [data, setData] = useState([]);
  const { data: users } = FetchData(`${BASE_URL}/users`);
  const [search, setSearch] = useState('');
  const inputRef = useRef(null);

  // For search
  useEffect(() => {
    if (users) {
      setData(users);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [users]);

  if (!data.length) {
    return <p>Loading...</p>;
  }

  // Toggle details
  const toggleDetails = (index) => {
    setVisibleDetails(visibleDetails === index ? null : index);
  };

  // Filter the data based on search input for both name and email
  const filteredData = data.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase())
  );

  // Check if filtered data is empty
  const isNotFound = filteredData.length === 0 && search.trim() !== '';

  // Block User
  const handleToggleBlock = async (user) => {
    const url = `${BASE_URL}/users/${user._id}/${
      user.isActive ? 'block-user' : 'unblock-user'
    }`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          isActive: !user.isActive, // Toggle the isActive status
        }),
      });
      if (response.ok) {
        console.log(
          `User ${user.isActive ? 'blocked' : 'unblocked'} successfully`
        );
        // Update the user status locally
        setData((prevData) =>
          prevData.map((u) =>
            u._id === user._id ? { ...u, isActive: !user.isActive } : u
          )
        );
      } else {
        console.error('Failed to toggle user status');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='p-4'>
      <div className='overflow-x-auto'>
        <div className='rounded-md px-4 bg-white label lg:w-[100%] md:w-[70%] border-none items-center flex justify-between mb-5'>
          <FaSearch />
          <input
            type='search'
            ref={inputRef}
            value={search}
            onChange={(e) => setSearch(e.target.value)} // Update search state
            placeholder='Search by User Name or Email'
            className='px-4 bg-transparent focus:outline-none md:w-[100%] lg:w-[100%] h-[45px] items-center'
          />
        </div>
        {isNotFound ? (
          <div className='flex justify-center items-center h-32'>
            <p className='text-red-500 text-lg font-bold'>No user found</p>
          </div>
        ) : (
          <table className='min-w-full bg-white rounded-lg shadow-md'>
            <thead>
              <tr className='lg:text-left md:text-left bg-white'>
                <th className='p-4'>Image</th>
                <th className='p-4'>Name</th>
                <th className='p-4 hidden lg:table-cell'>Email</th>
                <th className='p-4 hidden lg:table-cell'>Phone</th>
                <th className='p-4 hidden lg:table-cell'>Role</th>
                <th className='p-4 hidden lg:table-cell '>Address</th>
                <th className='p-4 hidden lg:table-cell'>Status</th>
                <th className='p-4 lg:pl-10'>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user, index) => (
                <React.Fragment key={index}>
                  {/* Table row for each user */}
                  <tr className='border-t lg:text-left'>
                    <td className='p-4'>
                      <div className='flex items-center space-x-2'>
                        <img
                          src={
                            user.image || 'http://www.gravatar.com/avatar/?d=mp'
                          }
                          alt={user.name}
                          className='h-12 w-12 rounded-full object-cover'
                        />
                      </div>
                    </td>
                    <td className='p-4'>{user.name}</td>
                    <td className='p-4 hidden lg:table-cell'>{user.email}</td>
                    <td className='p-4 hidden lg:table-cell'>
                      {user.phone || 'Not provided'}
                    </td>
                    <td className='p-4 hidden lg:table-cell'>
                      {user.role || 'Not specified'}
                    </td>
                    <td className='p-4 hidden lg:table-cell w-[200px]'>
                      {user.address?.street}, {user.address?.city},
                      {user.address?.state}, {user.address?.zipCode},
                      {user.address?.country}
                    </td>

                    <td className='pl-7 hidden lg:table-cell text-[24px] lg:items-center lg:justify-center '>
                      {user.isActive ? (
                        <FaCheck className='text-green-500' />
                      ) : (
                        <FaTimes className='text-red-500' />
                      )}
                    </td>

                    <td className='p-4'>
                      <div className='flex space-x-2'>
                        <button
                          className='text-black lg:hidden'
                          onClick={() => toggleDetails(index)}
                        >
                          <FaEye />
                        </button>

                        <button
                          className='text-white w-[90px] font-bold p-1 flex gap-4 items-center justify-center bg-primary rounded-md'
                          onClick={() => handleToggleBlock(user)}
                        >
                          {user.isActive ? 'Block' : 'Unblock'}
                        </button>
                      </div>
                    </td>
                  </tr>
                  {visibleDetails === index && (
                    <tr className='bg-gray-50'>
                      {/* User Details */}
                      <td
                        colSpan='6'
                        className='p-4 lg:text-left lg:hidden'
                      >
                        <div className='space-y-2'>
                          <p>
                            <strong>Email:</strong> {user.email}
                          </p>
                          <p>
                            <strong>Phone:</strong> {user.phone}
                          </p>
                          <p>
                            <strong>Address:</strong> {user.address?.street},{' '}
                            {user.address?.city}, {user.address?.state},{' '}
                            {user.address?.zipCode}, {user.address?.country}
                          </p>
                          <p>
                            <strong>Role:</strong> {user.role}
                          </p>
                          <p className='flex gap-2'>
                            <strong>Status:</strong>
                            {user.isVerified ? (
                              <FaCheck className='text-green-500' />
                            ) : (
                              <FaTimes className='text-red-500' />
                            )}
                          </p>
                          <p>
                            <button className='text-white w-[100px] font-bold p-1 flex gap-4 items-center justify-center bg-primary rounded-md'>
                              Block
                            </button>
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserTable;
