import React, { useState, useEffect } from 'react';
import FetchData from '../../hooks/fetchData';
import { BASE_URL, token } from '../../config';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loaders/Loader';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cart = location.state?.cart || [];

  // Calculate subtotal, shipping, and total
  const subtotal = cart.reduce(
    (total, item) => total + item.product.price * item.quantity,
    0
  );

  const shipping = 0;
  const total = subtotal + shipping;

  const { data: user, loading } = FetchData(`${BASE_URL}/users/profile`);

  // State for form data
  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    address: {
      street: user?.address?.street || '',
      city: user?.address?.city || '',
      state: user?.address?.state || '',
      zipCode: user?.address?.zipCode || '',
      country: user?.address?.country || '',
    },
  });

  // Set form data with user data
  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        address: {
          street: user.address?.street || '',
          city: user.address?.city || '',
          state: user.address?.state || '',
          zipCode: user.address?.zipCode || '',
          country: user.address?.country || '',
        },
      });
    }
  }, [user]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('address')) {
      const fieldName = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [fieldName]: value,
        },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Place the order
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Prepare order data
      const orderData = {
        products: cart.map((item) => ({
          productId: item.product._id,
          quantity: item.quantity,
        })),
        totalAmount: total,
        shippingAddress: formData.address,
      };

      // Place the order
      const orderResponse = await fetch(`${BASE_URL}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(orderData),
      });

      const orderDataResponse = await orderResponse.json();
      if (!orderResponse.ok) throw new Error(orderDataResponse.message);

      // Clear the cart after order placement
      const clearCartResponse = await fetch(`${BASE_URL}/cart`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!clearCartResponse.ok) {
        throw new Error('Failed to clear cart');
      }

      toast.success('Your order was placed successfully !');
      navigate('/');
    } catch (error) {
      console.log(error);
      toast.error('Login to place an order');
    }
  };

  return (
    <div className='p-6 flex flex-col items-center justify-center min-h-[100vh] pt-[150px]'>
      <h2 className='text-2xl font-bold mb-4 pt-5'>Billing Details</h2>
      {loading ? (
        <Loader />
      ) : (
        <div className='flex flex-col md:flex-row w-full max-w-4xl pt-3'>
          {/* Form */}
          <div className='w-full md:w-1/2 p-4 space-y-4'>
            <input
              type='text'
              placeholder='Full Name'
              className='border p-2 w-full'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type='text'
              placeholder='Street Address'
              className='border p-2 w-full'
              name='address.street'
              value={formData.address.street}
              onChange={handleInputChange}
            />
            <input
              type='text'
              placeholder='City'
              className='border p-2 w-full'
              name='address.city'
              value={formData.address.city}
              onChange={handleInputChange}
            />
            <input
              type='text'
              placeholder='State'
              className='border p-2 w-full'
              name='address.state'
              value={formData.address.state}
              onChange={handleInputChange}
            />
            <input
              type='text'
              placeholder='Zip Code'
              className='border p-2 w-full'
              name='address.zipCode'
              value={formData.address.zipCode}
              onChange={handleInputChange}
            />
            <input
              type='text'
              placeholder='Country'
              className='border p-2 w-full'
              name='address.country'
              value={formData.address.country}
              onChange={handleInputChange}
            />
            <input
              type='text'
              placeholder='Phone Number'
              className='border p-2 w-full'
              name='phone'
              value={formData.phone}
              onChange={handleInputChange}
            />
            <input
              type='email'
              placeholder='Email Address'
              className='border p-2 w-full'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              readOnly
            />
            <div>
              <input
                type='checkbox'
                id='save-info'
                className='mr-2'
              />
              <label htmlFor='save-info'>
                Save this information for faster check-out next time
              </label>
            </div>
          </div>

          {/* Cart */}
          <div className='w-full md:w-1/2 p-4 border rounded-lg'>
            {cart.map((item, index) => (
              <div
                key={index}
                className='flex justify-between items-center mb-2'
              >
                <img
                  src={item.product.images[0]}
                  alt={item.product.name}
                  className='w-16 h-16 object-contain'
                />
                <span>{item.product.name}</span>
                <span>
                  {item.quantity} x ${item.product.price.toFixed(2)}
                </span>
              </div>
            ))}
            <div className='flex justify-between items-center mt-4'>
              <span>Subtotal:</span>
              <span>${subtotal.toFixed(2)}</span>
            </div>
            <div className='flex justify-between items-center'>
              <span>Shipping:</span>
              <span>{shipping === 0 ? 'Free' : shipping}</span>
            </div>
            <div className='flex justify-between items-center mt-4 font-bold'>
              <span>Total:</span>
              <span>${total.toFixed(2)}</span>
            </div>

            <div className='mt-4'>
              <div className='flex items-center'>
                <input
                  type='radio'
                  id='bank'
                  name='payment'
                  className='mr-2'
                />
                <label htmlFor='bank'>Bank</label>
              </div>
              <div className='flex items-center'>
                <input
                  type='radio'
                  id='cod'
                  name='payment'
                  className='mr-2'
                />
                <label htmlFor='cod'>Cash on delivery</label>
              </div>
            </div>

            <div className='flex mt-4'>
              <input
                type='text'
                placeholder='Coupon Code'
                className='border p-2 flex-1'
              />
              <button className='bg-primary text-white px-4 py-2'>
                Apply Coupon
              </button>
            </div>

            <button
              className='bg-primary text-white px-4 py-2 mt-4 w-full'
              onClick={handleSubmit}
            >
              Place Order
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
