import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../config';
import { toast } from 'react-toastify';
import Loader from '../../components/Loaders/Loader';
import { FaEye } from 'react-icons/fa';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null); // Track selected order for modal

  // Fetch orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${BASE_URL}/orders/my-orders`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const result = await response.json();

        if (response.ok) {
          // Sort orders by creation date in descending order
          const sortedOrders = result.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setOrders(sortedOrders);
        } else {
          console.log(result.message);
          toast.error('Failed to fetch orders');
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  // Modal
  const openModal = (order) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='flex flex-col items-start justify-start pt-5'>
          <h1 className='text-3xl font-bold mb-4'>Orders</h1>
          {orders.length > 0 ? (
            <table className='w-[100%] table-auto border-collapse'>
              {/* Display orders */}
              <thead>
                <tr className='bg-gray-100 text-gray-900 border-b border-gray-300'>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Product
                  </th>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Quantity
                  </th>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Price
                  </th>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Total
                  </th>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Shipping Address
                  </th>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Status
                  </th>
                  <th className='px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider'>
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <React.Fragment key={order._id}>
                    <tr>
                      <td className='border px-4 py-2'>
                        {order.products.slice(0, 2).map((product) => (
                          <div key={product.product._id}>
                            <p>{product.product.name}</p>
                          </div>
                        ))}
                        {order.products.length > 2 && (
                          <div className='text-gray-500 mt-2'>
                            <button onClick={() => openModal(order)}>
                              and {order.products.length - 2} more...
                            </button>
                          </div>
                        )}
                      </td>
                      <td className='border px-4 py-2'>
                        {order.products.slice(0, 2).map((product) => (
                          <div key={product.product._id}>
                            <p>{product.quantity}</p>
                          </div>
                        ))}
                      </td>
                      <td className='border px-4 py-2'>
                        {order.products.slice(0, 2).map((product) => (
                          <div key={product.product._id}>
                            <p>${product.product.price}</p>
                          </div>
                        ))}
                      </td>
                      <td className='border px-4 py-2'>${order.totalAmount}</td>
                      <td className='border px-4 py-2'>
                        <p>
                          {order.shippingAddress.street},{' '}
                          {order.shippingAddress.city},{' '}
                          {order.shippingAddress.state},{' '}
                          {order.shippingAddress.zipCode},{' '}
                          {order.shippingAddress.country}
                        </p>
                      </td>
                      <td className='border px-4 py-2'>
                        <span
                          className={`px-2 py-1 rounded-md ${
                            order.status === 'Success'
                              ? 'bg-green-100 text-green-700'
                              : order.status === 'Pending'
                              ? 'bg-gray-100 text-gray-700'
                              : 'bg-red-100 text-red-700'
                          }`}
                        >
                          {order.status}
                        </span>
                      </td>
                      <td className='border px-10'>
                        <button
                          className='hover:text-black text-gray-800'
                          onClick={() => openModal(order)}
                        >
                          <FaEye />
                        </button>
                      </td>
                    </tr>

                    {selectedOrder === order && (
                      <div className='fixed inset-0 flex items-center justify-center z-50'>
                        <div
                          className='absolute inset-0 bg-black opacity-50'
                          onClick={closeModal}
                        ></div>
                        <div className='bg-white p-6 rounded-lg z-10'>
                          <div className='flex justify-between mb-4'>
                            <div>
                              <h2 className='text-lg font-bold flex justify-start mb-2'>
                                Order Details
                              </h2>
                            </div>
                            <div>
                              <button
                                className='text-gray-600 hover:text-gray-800 text-[20px]'
                                onClick={closeModal}
                              >
                                <i class='fa-solid fa-xmark'></i>
                              </button>
                            </div>
                          </div>
                          <p>
                            <strong>Order Date:</strong>{' '}
                            {new Date(order.createdAt).toLocaleString()}
                          </p>
                          <table className='min-w-full mt-4'>
                            <tbody>
                              {order.products.map((product) => (
                                <tr key={product.product._id}>
                                  <td>
                                    <img
                                      src={product.product.images}
                                      alt={product.product.name}
                                      className='w-10 h-10 rounded-full mr-3 object-contain bg-gray-200'
                                    />
                                  </td>
                                  <td>
                                    {product.product.name} -{product.quantity} -
                                    CHF
                                    {product.product.price}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td
                                  colSpan='2'
                                  className='text-center text-lg pt-6'
                                >
                                  <strong>
                                    {' '}
                                    Total: CFH {order.totalAmount}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <p>You don't have any orders yet.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Orders;
